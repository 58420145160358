<template>
  <div>
    <b-form @submit.prevent>
      <b-row
        class="d-sm-flex justify-content-center align-content-center mx-auto"
      >
        <b-card class="col-12">
          <b-card-body class="my-0 py-1 mx-auto">
            <b-row
              class="d-sm-flex justify-content-center align-items-end mx-auto"
            >
              <b-col cols="12" md="6" sm="9">
                <label>{{
                  $t("Pages.Tools.Thumbnail Downloader.Title.enter_youtube")
                }}</label>
                <input
                  type="text"
                  v-model="link"
                  :dir="
                    () =>
                      localStorage.getItem('language') == 'fa' ? 'rtl' : 'ltr'
                  "
                  class="w-100 form-control"
                />
              </b-col>
              <b-col
                cols="12"
                md="2"
                sm="3"
                class="mb-md-0 mt-sm-0 mt-2 text-sm-left text-center"
              >
                <input
                  type="submit"
                  class="btn btn-primary form-control"
                  :value="$t('Pages.Tools.Thumbnail Downloader.Title.Download')"
                  @click="getThumbnailPictures"
                />
              </b-col>
            </b-row>
            <div v-if="badResponse" class="d-flex justify-content-center mt-3">
              <b-alert show variant="danger">
                {{ $t("Pages.Tools.Thumbnail Downloader.Title.wrong_link") }}
              </b-alert>
            </div>
          </b-card-body>
        </b-card>
      </b-row>
    </b-form>

    <p v-text="hdSize"></p>

    <div v-if="hdSize && mediumSize && smallSize" class="row">
      <b-col cols="12" md="4" sm="6" class="mb-3">
        <b-img class="img-fluid w-100" :src="hdSize" />
        <div class="text-center mt-1">
          <b-button
            class="mr-1"
            variant="primary"
            @click="downloadThumbnail(hdSize)"
          >
            <feather-icon size="14" icon="DownloadCloudIcon" />
            {{ $t("Pages.Tools.Thumbnail Downloader.Title.full_hd") }}
          </b-button>
          <span class="btn btn-light no-effect" dir="ltr">1280×720</span>
        </div>
      </b-col>
      <b-col cols="12" md="4" sm="6" class="mb-3">
        <b-img class="img-fluid w-100" :src="hdSize" />
        <div class="text-center mt-1">
          <b-button
            class="mr-1"
            variant="primary"
            @click="downloadThumbnail(mediumSize)"
          >
            <feather-icon size="14" icon="DownloadCloudIcon" />
            {{ $t("Pages.Tools.Thumbnail Downloader.Title.Medium") }}
          </b-button>
          <span class="btn btn-light no-effect" dir="ltr">480×360</span>
        </div>
      </b-col>
      <b-col cols="12" md="4" sm="6" class="mb-3">
        <b-img class="img-fluid w-100" :src="smallSize" />
        <div class="text-center mt-1">
          <b-button
            class="mr-1"
            variant="primary"
            @click="downloadThumbnail(smallSize)"
          >
            <feather-icon size="14" icon="DownloadCloudIcon" />
            {{ $t("Pages.Tools.Thumbnail Downloader.Title.Small") }}
          </b-button>
          <span class="btn btn-light no-effect" dir="ltr">320×180</span>
        </div>
      </b-col>
      <b-col cols="12">
        <b-card class="">
          <b-row>
            <b-col lg="3" md="6">
              <a
                href="https://www.youtube.com/watch?v=Qu6dWcXQH1c&list=PLuUOgeIMlkPk1bgDxNl7mN5dw8XryoP83"
              >
                <b-img
                  class="img-fluid w-100 radius"
                  src="https://img.youtube.com/vi/Qu6dWcXQH1c/maxresdefault.jpg"
                />
              </a>
            </b-col>
            <b-col lg="9" md="6">
              <div class="suggest-course">
                <span class="offer-box">{{
                  $t("Pages.Tools.Thumbnail Downloader.Title.Offer")
                }}</span>
                <h1>
                  <a
                    href="https://www.youtube.com/watch?v=Qu6dWcXQH1c&list=PLuUOgeIMlkPk1bgDxNl7mN5dw8XryoP83"
                    target="_blank"
                    >{{
                      $t(
                        "Pages.Tools.Thumbnail Downloader.Title.design_course"
                      )
                    }}<span>{{
                      $t("Pages.Tools.Thumbnail Downloader.Title.Free")
                    }}</span></a
                  >
                </h1>
                <p>
                  {{ $("Pages.Tools.Thumbnail Downloader.Title.adv_txt") }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BAlert,
    BLink,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BImg,
    BCardBody,
  },
  data() {
    return {
      link: "",
      pics: "",
      mediumSize: null,
      smallSize: null,
      badResponse: null,
      hdSize: null,
    };
  },

  methods: {
    getThumbnailPictures() {
      this.badResponse = null;
      let data = new FormData();
      data.append("link", this.link);
      this.$http
        .post("/api/tools/thumbnail-downloader", data)
        .then((thumbnailLinks) => {
          this.hdSize = thumbnailLinks.data[2];
          this.mediumSize = thumbnailLinks.data[1];
          this.smallSize = thumbnailLinks.data[0];
        })
        .catch((err) => {
          this.hdSize = null;
          this.mediumSize = null;
          this.smallSize = null;
        });
    },
    downloadThumbnail(linkThumbnail) {
      this.$http({
        url: `api/tools/thumbnail-downloader/download?file_name=${
          linkThumbnail.split("/")[linkThumbnail.split("/").length - 1]
        }`,
        method: "POST",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fURL = document.createElement("a");
        fURL.href = fileURL;
        fURL.setAttribute(
          "download",
          linkThumbnail.split("/")[linkThumbnail.split("/").length - 1]
        );
        document.body.appendChild(fURL);
        fURL.click();
      });
    },
  },
};
</script>
<style scoped>
[dir] .btn {
  width: fit-content;
}

.no-effect {
  cursor: default !important;
}

.no-effect:hover {
  background-color: #f6f6f6 !important;
}
</style>
<style scoped>
.radius {
  border-radius: 0.428rem;
}
.suggest-course {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 10px;
}
.suggest-course h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: normal;
}
.suggest-course .offer-box {
  background-color: #db2d36;
  color: white;
  font-size: 12px;
  border-radius: 0.428rem;
  padding: 5px 8px;
  width: fit-content;
}
.suggest-course p {
  font-size: 16px;
  line-height: normal;
}
@media (max-width: 768px) {
  .suggest-course {
    text-align: center;
  }
  .suggest-course .offer-box {
    margin: 20px auto 10px auto;
  }
  .suggest-course h1 {
    font-size: 30px;
  }
}
</style>
